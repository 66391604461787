body {
  margin: 0;
}

a {
  text-decoration: none;
  color: #575555;
  transition: all 0.2s ease;
}

a:hover {
  color: #272525;
}